<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cierre guia</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Cierre guia</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section
          class="content"
          v-if="$store.getters.can('hidrocarburos.cierreGuias.aprobarCierre')"
        >
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div>
                <div class="card-body p-0">
                  <table
                    style="font-size: 85%"
                    class="table table-bordered table-striped table-hover table-responsive table-sm text-center"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th rowspan="2" class="p-4">Observación</th>
                        <th class="p-4" rowspan="2" style="min-width: 5em">
                          N°
                        </th>
                        <th rowspan="2" style="min-width: 15em">
                          Número de guia
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="filtros.nGuia"
                            placeholder="# Guia"
                            @keyup.enter="cargarAprobacion()"
                          />
                        </th>
                        <th rowspan="2" style="min-width: 20em">
                          Ventana descargue
                          <v-select
                            class="form-control form-control-sm p-0"
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="ventana_descargue"
                            placeholder="Ventana Descargue"
                            label="nombre"
                            :options="listasForms.ventanasDescargue"
                            @input="cargarAprobacion()"
                          >
                          </v-select>
                        </th>
                        <th rowspan="2" style="min-width: 15em">
                          Placa Vehiculo
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="filtros.nPlaca"
                            placeholder="Vehiculo"
                            @keyup.enter="cargarAprobacion()"
                          />
                        </th>
                        <th colspan="3">Volumenes GOV</th>
                        <th colspan="3">Volumenes GSV</th>
                        <th colspan="3">Volumenes NSV</th>
                        <th colspan="3">Volumenes BSW</th>
                        <th colspan="3">Volumenes API</th>
                        <th rowspan="2" class="p-4">Temperatura</th>
                        <th rowspan="2" class="p-4" style="min-width: 8em">
                          % SAL
                        </th>
                        <th rowspan="2" class="p-4" style="min-width: 10em">
                          % Azufre
                        </th>
                        <th colspan="2">Fechas</th>
                        <th colspan="2">Novedades</th>
                        <th rowspan="2" class="p-4">Acción</th>
                      </tr>

                      <tr>
                        <th class="p-4">Cargue</th>
                        <th class="p-4">Descargue</th>
                        <th class="p-4">Diferencia</th>
                        <th class="p-4">Cargue</th>
                        <th class="p-4">Descargue</th>
                        <th class="p-4">Diferencia</th>
                        <th class="p-4">Cargue</th>
                        <th class="p-4">Descargue</th>
                        <th class="p-4">Diferencia</th>
                        <th class="p-4">Cargue</th>
                        <th class="p-4">Descargue</th>
                        <th class="p-4">Diferencia</th>
                        <th class="p-4">Cargue</th>
                        <th class="p-4">Descargue</th>
                        <th class="p-4">Diferencia</th>
                        <th>
                          Enturne
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_enturne"
                            placeholder="fecha"
                            @change="cargarAprobacion()"
                          />
                        </th>
                        <th>
                          Descargue
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_descargue"
                            placeholder="fecha"
                            @change="cargarAprobacion()"
                          />
                        </th>

                        <th style="min-width: 10em">
                          Novedad 1
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.novedad_1"
                            @change="cargarAprobacion()"
                          >
                            <option value="">Seleccione</option>
                            <option
                              v-for="novedad_1 in listasForms.novedades"
                              :key="novedad_1.numeracion"
                              :value="novedad_1.numeracion"
                            >
                              {{ novedad_1.descripcion }}
                            </option>
                          </select>
                        </th>
                        <th style="min-width: 12em">
                          Novedad 2
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.novedad_2"
                            @change="cargarAprobacion()"
                          >
                            <option value="">Seleccione</option>
                            <option
                              v-for="novedad_2 in listasForms.novedades"
                              :key="novedad_2.numeracion"
                              :value="novedad_2.numeracion"
                            >
                              {{ novedad_2.descripcion }}
                            </option>
                          </select>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(data, index) in cargarArregloAprobacion.data"
                        :key="data.id"
                      >
                        <td class="text-center">Cierre por aprobar</td>

                        <td>{{ index + 1 }}</td>

                        <td v-if="data.guia">
                          {{ data.guia.numero }}-{{
                            data.guia.digito_verificacion
                          }}
                        </td>
                        <td v-else>{{ data.guia_id }}</td>
                        <td v-if="data.guia">
                          <div v-if="data.guia.viaje.ventana_descargue != null">
                            {{ data.guia.viaje.ventana_descargue.nombre }}
                          </div>
                          <div v-else>-----------</div>
                        </td>
                        <td v-else>Sin guia asociada</td>

                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0].vehiculo.placa }}
                        </td>
                        <td v-else>Sin guia asociada</td>

                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0]["gov"] }}
                        </td>
                        <td v-else>Sin guia asociada</td>
                        <td v-if="data.barriles_gov">
                          {{ data.barriles_gov }}
                        </td>
                        <td v-else>---</td>
                        <td
                          v-if="
                            data.diferencia_gov != 100 ||
                              data.diferencia_gov != -100
                          "
                        >
                          <div
                            v-if="
                              data.diferencia_gov >= 2 ||
                                data.diferencia_gov <= -2
                            "
                          >
                            <div v-if="data.diferencia_gov >= 2">
                              <span class="badge badge-warning">
                                % {{ data.diferencia_gov }}</span
                              >
                            </div>
                            <div v-if="data.diferencia_gov <= -2">
                              <span class="badge badge-danger">
                                % {{ data.diferencia_gov }}</span
                              >
                            </div>
                          </div>
                          <div v-else>% {{ data.diferencia_gov }}</div>
                        </td>
                        <td v-else>---</td>

                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0]["gsv"] }}
                        </td>
                        <td v-else>Sin guia asociada</td>

                        <td>
                          <span v-if="formActualizar && idActualizar == index">
                            <!-- Formulario para actualizar -->
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="tiempo"
                              v-model="form.barriles_gsv"
                              :class="
                                $v.form.barriles_gsv.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </span>
                          <span v-else>
                            <!-- Dato nombre -->
                            {{ data.barriles_gsv }}
                          </span>
                        </td>
                        <td
                          v-if="
                            data.diferencia_gsv != 100 ||
                              data.diferencia_gsv != -100
                          "
                        >
                          <div
                            v-if="
                              data.diferencia_gsv >= 2 ||
                                data.diferencia_gsv <= -2
                            "
                          >
                            <div v-if="data.diferencia_gsv >= 2">
                              <span class="badge badge-warning">
                                % {{ data.diferencia_gsv }}</span
                              >
                            </div>
                            <div v-if="data.diferencia_gsv <= -2">
                              <span class="badge badge-danger">
                                % {{ data.diferencia_gsv }}</span
                              >
                            </div>
                          </div>
                          <div v-else>% {{ data.diferencia_gsv }}</div>
                        </td>
                        <td v-else>---</td>
                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0]["nsv"] }}
                        </td>
                        <td v-else>Sin guia asociada</td>
                        <td>
                          <span v-if="formActualizar && idActualizar == index">
                            <!-- Formulario para actualizar -->
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="tiempo"
                              v-model="form.barriles_nsv"
                              :class="
                                $v.form.barriles_nsv.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </span>
                          <span v-else>
                            <!-- Dato nombre -->
                            {{ data.barriles_nsv }}
                          </span>
                        </td>
                        <td
                          v-if="
                            data.diferencia_nsv != 100 ||
                              data.diferencia_nsv != -100
                          "
                        >
                          <div
                            v-if="
                              data.diferencia_nsv >= 2 ||
                                data.diferencia_nsv <= -2
                            "
                          >
                            <div v-if="data.diferencia_nsv >= 2">
                              <span class="badge badge-warning">
                                % {{ data.diferencia_nsv }}</span
                              >
                            </div>
                            <div v-if="data.diferencia_nsv <= -2">
                              <span class="badge badge-danger">
                                % {{ data.diferencia_nsv }}</span
                              >
                            </div>
                          </div>
                          <div v-else>% {{ data.diferencia_nsv }}</div>
                        </td>
                        <td v-else>---</td>
                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0]["bsw"] }}
                        </td>
                        <td v-else>Sin guia asociada</td>
                        <td>
                          <span v-if="formActualizar && idActualizar == index">
                            <!-- Formulario para actualizar -->
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="tiempo"
                              v-model="form.porcentaje_bsw"
                              :class="
                                $v.form.porcentaje_bsw.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </span>
                          <span v-else>
                            <!-- Dato nombre -->
                            {{ data.porcentaje_bsw }}
                          </span>
                        </td>
                        <td
                          v-if="
                            data.diferencia_bsw != 100 ||
                              data.diferencia_bsw != -100
                          "
                        >
                          <div
                            v-if="
                              data.diferencia_bsw >= 2 ||
                                data.diferencia_bsw <= -2
                            "
                          >
                            <div v-if="data.diferencia_bsw >= 2">
                              <span class="badge badge-warning">
                                % {{ data.diferencia_bsw }}</span
                              >
                            </div>
                            <div v-if="data.diferencia_bsw <= -2">
                              <span class="badge badge-danger">
                                % {{ data.diferencia_bsw }}</span
                              >
                            </div>
                          </div>
                          <div v-else>% {{ data.diferencia_bsw }}</div>
                        </td>
                        <td v-else>---</td>
                        <td v-if="data.guia">
                          {{ data.guia.det_guias[0]["api"] }}
                        </td>
                        <td v-else>Sin guia asociada</td>
                        <td>
                          <span v-if="formActualizar && idActualizar == index">
                            <!-- Formulario para actualizar -->
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="tiempo"
                              v-model="form.api"
                              :class="
                                $v.form.api.$invalid ? 'is-invalid' : 'is-valid'
                              "
                            />
                          </span>
                          <span v-else>
                            <!-- Dato nombre -->
                            {{ data.api }}
                          </span>
                        </td>
                        <td
                          v-if="
                            data.diferencia_api != 100 ||
                              data.diferencia_api != -100
                          "
                        >
                          <div
                            v-if="
                              data.diferencia_api >= 2 ||
                                data.diferencia_api <= -2
                            "
                          >
                            <div v-if="data.diferencia_api >= 2">
                              <span class="badge badge-warning">
                                % {{ data.diferencia_api }}</span
                              >
                            </div>
                            <div v-if="data.diferencia_api <= -2">
                              <span class="badge badge-danger">
                                % {{ data.diferencia_api }}</span
                              >
                            </div>
                          </div>
                          <div v-else>% {{ data.diferencia_api }}</div>
                        </td>
                        <td v-else>---</td>
                        <td>{{ data.temperatura }}</td>
                        <td>{{ data.porcentaje_sal }}</td>
                        <td>{{ data.porcentaje_azufre }}</td>
                        <td>
                          {{ data.fecha_hora_enturne_descargue }}
                        </td>
                        <td>
                          <!-- Dato nombre -->
                          {{ data.fecha_hora_descargue }}
                        </td>

                        <td v-if="data.novedad_1">
                          <div v-if="listasForms.novedades[data.novedad_1 - 1]">
                            {{
                              listasForms.novedades[data.novedad_1 - 1]
                                .descripcion
                            }}
                          </div>
                        </td>
                        <td v-else>Ninguna</td>
                        <td v-if="data.novedad_2">
                          <div v-if="listasForms.novedades[data.novedad_2 - 1]">
                            {{
                              listasForms.novedades[data.novedad_2 - 1]
                                .descripcion
                            }}
                          </div>
                        </td>
                        <td v-else>Ninguna</td>

                        <td v-if="data.observacion == 30">
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-success"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.cierreGuias.guardarAprobarCierre'
                                )
                              "
                              @click="aprobar(data.id)"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm bg-danger"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.cierreGuias.anularAprobarCierre'
                                )
                              "
                              @click="desaprobar(data.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="cargarArregloAprobacion.total">
                    <p>
                      Mostrando del <b>{{ cargarArregloAprobacion.from }}</b> al
                      <b>{{ cargarArregloAprobacion.to }}</b> de un total:
                      <b>{{ cargarArregloAprobacion.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="cargarArregloAprobacion"
                    @pagination-change-page="cargarAprobacion"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
              <!-- /.card-header -->
            </div>
            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CierreGuiaAprobar",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      formActualizar: false,

      filtros: { observacion: null, nGuia: null, nPlaca: null },
      ventana_descargue: null,
      form: {
        id: null,
        guia_id: null,
        barriles_gsv: null,
        barriles_nsv: null,
        api: null,
        porcentaje_bsw: null,
      },
      usuario_id: this.$store.getters.getUser.id,
      cargarArregloAprobacion: {},
      listasForms: {
        novedades: [],
      },
    };
  },
  validations: {
    form: {
      barriles_gsv: {
        required,
      },
      barriles_nsv: {
        required,
      },
      api: {
        required,
      },
      porcentaje_bsw: {
        required,
      },
    },
  },
  methods: {
    getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    async aprobar(id) {
      this.$swal({
        title: "Esta seguro de aprobar este cierre de guia?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          axios
            .get("/api/hidrocarburos/cierreGuias/aprobarCierre", {
              params: { user_id: this.usuario_id, cierre_id: id },
            })
            .then(async (response) => {
              if (this.cargarArregloAprobacion.total % 10 == 1) {
                await this.cargarAprobacion(
                  this.cargarArregloAprobacion.current_page - 1
                );
              } else {
                await this.cargarAprobacion(
                  this.cargarArregloAprobacion.current_page
                );
              }

              this.$swal({
                icon: "success",
                title:
                  "La aprobacion del cierre de guia se realizo exitosamente",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async desaprobar(id) {
      this.$swal({
        title: "Esta seguro desea desaprobar esta cierre de guia?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .get("/api/hidrocarburos/cierreGuias/desaprobarCierre", {
              params: { user_id: this.usuario_id, cierre_id: id },
            })
            .then(async (response) => {
              if (this.cargarArregloAprobacion.total % 10 == 1) {
                await this.cargarAprobacion(
                  this.cargarArregloAprobacion.current_page - 1
                );
              } else {
                await this.cargarAprobacion(
                  this.cargarArregloAprobacion.current_page
                );
              }

              this.$swal({
                icon: "success",
                title:
                  "La desaprobacion del cierre de guia se realizo exitosamente",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async cargarAprobacion(page) {
      let me = this;
      let ventanaDescargue = null;

      if (me.ventana_descargue !== null) {
        ventanaDescargue = me.ventana_descargue.id;
      }

      axios
        .get("/api/hidrocarburos/cierreGuias/indexCierre?page=" + page, {
          params: {
            user_id: me.usuario_id,
            nPlaca: me.filtros.nPlaca,
            nGuia: me.filtros.nGuia,
            novedad_1: me.filtros.novedad_1,
            novedad_2: me.filtros.novedad_2,
            fecha_enturne: me.filtros.fecha_enturne,
            fecha_descargue: me.filtros.fecha_descargue,
            observacion: me.filtros.observacion,
            ventanaDescargue: ventanaDescargue,
          },
        })
        .then((response) => {
          me.cargarArregloAprobacion = response.data;
        });
    },

    back() {
      return this.$router.replace("/Hidrocarburos/CierreGuia");
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    buscarVentana() {
      let me = this;
      var url = "api/hidrocarburos/ventanasEnturnamiento/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasDescargue = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
  async mounted() {
    await this.cargarAprobacion(1);
    await this.getNovedades();
    await this.buscarVentana();
  },
};
</script>
